/** For for prose sections, blogging concerns, Markdown, etc. */

/** Pretty syntax blocks */
pre.highlight {
  padding: 0.85rem 1rem;
  margin: 0;
  overflow-x: auto;
}

.highlighter-rouge,
.highlight {
  border-radius: 8px;
  font-size: 0.85rem;
  line-height: 1.4;
  font-family: var(--sl-font-mono);
}

.highlight,
.highlighter-rouge {
  position: relative;
}

/* Inline code blocks */
code.highlight,
code.highlighter-rouge {
  /** Layout */
  display: inline-block;
  font-size: 1rem;
  padding: 0 0.4em;
  border-radius: 6px;
  word-wrap: anywhere;

  /* Decoration */
  background-color: var(--code-background-color);
  color: var(--code-color);
}

#page-header__who-am-i {
  text-align: center;
}
