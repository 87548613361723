light-code::part(code) {
  font-size: 0.85rem;
  padding-top: 4px;
  border-top: 1px solid var(--divider-color);
}

light-code:defined > [slot="code"] {
  display: none;
}

light-code > [slot="code"] {
  display: block;
  overflow: auto;
  /* this creates "hard" line-wrapping where it will scroll horizontally. */
  white-space: pre;
  word-break: break-all;

}

light-code[wrap="soft"] > [slot="code"] {
  /* this creates "soft" line-wrapping. */
  word-break: break-word;
  white-space: pre-wrap;
}


light-code::part(line-inserted) {
  /** contrast sucks otherwise. */
  color: var(--syntax-fg);
}
