.table-of-contents {
  margin-inline-start: 1rem;
  min-width: 275px;
  max-width: 275px;
}

.table-of-contents * {
  max-width: 100%;
}

.table-of-contents__header {
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  font-weight: normal;
  color: var(--sl-color-neutral-800);
  font-size: var(--sl-font-size-x-large);
}

.table-of-contents__list {
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  list-style: none;
}

.table-of-contents__list a {
  color: var(--sl-color-neutral-500);
  text-decoration: none;
}

.table-of-contents__list li {
  text-align: start;
  border-inline-start: 4px solid var(--sl-color-neutral-400);
  padding-inline-start: 1rem;
}

.table-of-contents .side-nav__item:has(a:hover) {
  color: var(--sl-color-neutral-900);
  background-color: var(--sl-color-primary-100);
}

.table-of-contents__list li {
  padding: 0.5rem;
}

