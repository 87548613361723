/* Janky hack for iOS mobile */
.fixed-body {
  position: fixed;
  height: var(--viewport-height, 100%);
  width: 100%;
  /* Allow the main content to be scrolled,
  so we can adjust the scroll position with JS. */
  overflow: auto;
}

body:is(.default, .home) kr-layout {
  --menu-width: 0px;
  --main-width: 1fr;
  --aside-width: 0px;
}

kr-layout {
  --menu-width: auto;
  --main-width: 105ch;
  --aside-width: auto;
}

kr-layout,
kr-layout::part(skip-links) {
  background-color: var(--body-color);
}

kr-layout::part(body) {
  max-width: 100vw;
  justify-content: center;
  margin: 0 auto;
}

kr-layout::part(main) {
  background-color: var(--body-color);
}

main {
	max-width: 100vw;
	padding: 0 var(--main-padding-x) 2rem;
	width: 100%;
	margin: 0 auto;
	background-color: var(--body-color);
	color: var(--text-color);
}

:is(.default, .home) main {
  max-width: var(--main-width);
}

:is(.default, .home) .footer {
  width: 100vw;
}

:is(.doc, .index, .page, .post) main {
	background-color: var(--body-color);
}

