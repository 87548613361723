.side-nav__menu {
  margin: 0;
  padding-inline-start: 0;
  min-width: 250px;
  max-width: 100%;
}

.side-nav__item {
  padding: 8px;
  max-width: 100%;
  line-height: 1.3;
  font-size: 1.25rem;
  text-align: center;
}

.side-nav__link:is(:hover, :focus) {
  color: var(--link-color);
  text-decoration: underline;
}

.side-nav--desktop {
  display: none;
}

.side-nav__menu {
  list-style: none;
  padding: 0;
  padding-top: 1rem;
}

.side-nav__drawer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--sl-color-neutral-200);
  padding-bottom: 1rem;
}

.side-nav__item.is-active {
  border-inline-start: 4px solid var(--sl-color-primary-600);
  background-color: var(--sl-color-primary-100);
}

.side-nav__item.is-active,
.side-nav__item.is-active a {
  color: var(--sl-color-primary-700);
}

@media screen and (min-width: 1024px) {
  .side-nav--desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 275px;
  }

  .side-nav__drawer {
    display: none;
  }
}
