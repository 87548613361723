/* Tokens */
@import "./tokens/_layout.css";
@import "./tokens/_typography.css";

@import "./_normalize.css";
@import "./_components.css";
@import "./_content.css";
@import "./_layout.css";
@import "./_overrides.css";

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none !important;
  }

}

@media screen and (max-width: 1024px) {
  .desktop-only {
    display: none !important;
  }
}
