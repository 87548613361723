.hero {
  margin: 0 auto;
  padding: 16px;
  max-width: 600px;
}

.hero--mobile {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hero--mobile a {
  max-width: 100%;
  display: block;
  text-align: center;
}

.hero--mobile .logo__text{
  font-size: 2rem;
}

