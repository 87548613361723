/* Themes */
.theme-item {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}

[data-controller~='theme-switcher'],
[data-controller~='theme-switcher']:not(:defined) {
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
}

[data-controller~='theme-switcher']:defined {
  height: unset;
  width: unset;
  visibility: visible;
  opacity: 1;
  animation: fadeInAnimation ease 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

